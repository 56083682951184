import React from 'react'
import Layout from '../components/layout'

const SustainabilityGHG = ({ location }) => {
  return (
    <Layout location={location}>
    <section class="hero-carbon w-full flex justify-center items-center flex-col relative py-6 xl:py-28 text-center">
      <div class="lg:w-5/6 xl:max-w-5xl px-3">
        <h2 class="text-purple-100 text-4xl lg:text-5xl xl:text-8xl md:max-w-xl lg:max-w-7xl py-5 brmobile">Responsible Today, <br/>Sustainable Tomorrow.</h2></div>
        <p class='m-0 p-0 w-5/6 lg:max-w-4xl text-white'>At Vantage Circle, we advocate for a more sustainable and eco-friendly future for everyone and are dedicated to lowering GHG emissions.</p>
        <div class="grid justify-center gap-5 my-5 py-5 w-9/12 lg:max-w-xl">
      </div>
    </section>
    <section class='w-full flex place-content-center py-10 xl:py-16 bg-purple-100'>
      <div class='w-5/6 lg:max-w-7xl mx-auto'>
          <h3 className='text-base text-center text-gray-200'>O U R &nbsp; P H I L O S O P H Y</h3>
          <div class='mx-auto'>
            <h2 class='homepage-heading text-center mb-3 mt-1 lg:mt-4'>Navigating a Greener Path</h2>
            <p class='text-center max-w-5xl mx-auto text-gray-500'>Bargain Tech. Pvt. Ltd. (d/b/a Vantage Circle) is committed to playing a proactive role in building a low-carbon, sustainable future. We are aligned with GRI and SASB materiality standards and follow the TCFD framework to assess climate-related risks.</p>
            {/* <div class='w-full lg:w-2/6 mx-auto'><a class="vc-ghost-btn-black lato mx-auto lg:mx-0" style={{ borderRadius: '50px', padding: '10px 0px' }} href="https://res.cloudinary.com/vantagecircle/image/upload/v1694607648/prod/QES_Report_2022_23.pdf" target="_blank">Download Report 2022-23</a></div> */}
          </div>
          <div className='grid lg:grid-cols-2 place-content-center gap-10 mt-5 lg:mt-16 lg:mb-10 max-w-3xl mx-auto'>
              <a href='https://vantagecircle-my.sharepoint.com/personal/varsha_chakravarty_vantagecircle_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fvarsha%5Fchakravarty%5Fvantagecircle%5Fcom%2FDocuments%2FMicrosoft%20Teams%20Chat%20Files%2FOPINION%20STATEMENT%2D%20BARGAIN%20TECH%201%2Epdf&parent=%2Fpersonal%2Fvarsha%5Fchakravarty%5Fvantagecircle%5Fcom%2FDocuments%2FMicrosoft%20Teams%20Chat%20Files&ga=1' className='rounded-xl shadow border border-white bg-white transform hover:scale-105 duration-500'>
                  <div className=' w-full'>
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/10/GHG-Report-24-1.webp" />
                  </div>
                  <div className='mt-2 p-5 pb-10'>
                        <h2 className='homepage-small-heading pb-7 lg:h-24 text-gray-500'>GHG Verification Report</h2>
                        <div className='w-full flex place-content-center'>
                          <button className='vc-new-orange-border-btn-rounded-full text-orange'>
                            Download Report 2024
                          </button>
                        </div>
                  </div>
              </a>
              <a href='https://res.cloudinary.com/vantagecircle/image/upload/v1697535921/documents/GHG_2022-23.pdf' className='rounded-xl shadow border border-white bg-white transform hover:scale-105 duration-500'>
                  <div className=''>
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/10/GHG-Report-23-1.webp" />
                  </div>
                  <div className='mt-2 p-5 pb-10'>
                        <h2 className='homepage-small-heading pb-7 lg:h-24 text-gray-500'>Qualifying Explanatory Statement</h2>
                        <div className='w-full flex place-content-center'>
                          <button className='vc-new-orange-border-btn-rounded-full text-orange'>
                            Download Report 2023
                          </button>
                        </div>
                  </div>
              </a>
          </div>
      </div>
    </section>
    </Layout >
  )
}

export default SustainabilityGHG